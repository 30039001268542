/* src/App.css */
@import 'mapbox-gl/dist/mapbox-gl.css';

html, body {
  overflow: hidden; /* Prevents scrolling */
  height: 100%;
  margin: 0;
  touch-action: none; /* Prevents default touch actions */
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  overflow: hidden; /* Ensures no scrollbars appear */
  height: 100vh; /* Forces full viewport height */
}

.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 8px 16px;
  border-radius: 10px;
  font-weight: bold;
  z-index: 10;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}

.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #8a2be2;
  border: none;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

/* Marker Style */
.marker {
  color: #8a2be2;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  z-index: 11;
}

.file-input-button {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 30px;
  background-color: #3a3a3a;
  color: white;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  transition: background-color 0.3s;
  border: none;
}

.file-input-button:hover {
  background-color: #575757;
}

input[type="file"] {
  display: none;
}

.post-button, .cancel-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  border: none;
  transition: background-color 0.3s;
}

.post-button {
  background-color: #8a2be2;
  color: white;
}

.cancel-button {
  background-color: #3a3a3a;
  color: white;
}

.post-button:hover {
  background-color: #7a26d2;
}

.cancel-button:hover {
  background-color: #575757;
}

textarea.message-input {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  resize: vertical;
  box-sizing: border-box;

}

.upload-status {
  color: #8a2be2;
  font-size: 14px;
  margin-top: 5px;
}

/* Intro Modal Styling */
.intro-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  outline: none;
  z-index: 11;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
}

.intro-close-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  border: none;
  transition: background-color 0.3s;
  background-color: #8a2be2; /* Match theme color */
  color: white;
}

.intro-close-button:hover {
  background-color: #7a26d2;
}

.checkbox-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.checkbox-container label {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.checkbox-container input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

/* Help Button Styling */
.help-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #8a2be2; /* Same purple as the FAB */
  border: none;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 15;
}

.help-button:hover {
  background-color: #7a26d2; /* Slightly darker purple */
}

/* Help Modal Styling */
.help-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
  outline: none;
  z-index: 11;
  text-align: center;
}

.help-image {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  margin-bottom: 20px;
}

.help-modal .intro-close-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin: 10px 5px;
  border: none;
  transition: background-color 0.3s;
  background-color: #8a2be2; /* Same purple as the theme */
  color: white;
}

.help-modal .intro-close-button:hover {
  background-color: #7a26d2; /* Slightly darker purple */
}

