/* src/components/Map.css */
#map {
    width: 100%;
    height: 100vh;
    touch-action: manipulation;
  }
  
  /* User's current location marker */
  .user-location-marker {
    width: 24px; /* 1.5 times larger than typical markers */
    height: 24px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #007bff;
  }
  
  /* Increased "+" button size on mobile */
  .fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #7a26d2;
    border: none;
    color: white;
    border-radius: 50%;
    width: 70px; /* Increased width */
    height: 70px; /* Increased height */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px; /* Larger font size for "+" */
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .close-button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    border: none;
    transition: background-color 0.3s;
    background-color: #8a2be2; /* Match theme color */
    color: white;
  }